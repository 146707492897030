
import { PermissionEntityModel, PermissionQueryModel } from '@/entity-model/permission-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del, download } from './request';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/permission`;
class PermissionService implements ICRUDQ<PermissionEntityModel, PermissionQueryModel> {
    async create(model: PermissionEntityModel):Promise<PermissionEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<PermissionEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new PermissionEntityModel().toModel(res);
    }

    async update(model: PermissionEntityModel):Promise<PermissionEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: PermissionEntityModel):Promise<PermissionEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: PermissionQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        return _.map(res, item => item = new PermissionEntityModel().toModel(Object.assign({ level: 1 }, item)));
    }

    async getAllPermission(): Promise<any> {
        const url = `${URL_PATH}/permissions`;
        const res = await get(url);
        return _.map(res, item => new PermissionEntityModel().toModel(Object.assign({ level: 1 }, item)));
    }

    async getAllPermissionGroup() {
        const url = `${URL_PATH}/permission/groups`;
        const res = await get(url);
        return res;
    }

    async getResources(modelId: string):Promise<Array<Object>> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return _.get(res, 'resources');
    }

    async importPermission(groupId: string, file: any):Promise<Array<Object>> {
        const url = `${URL_PATH}/import`;
        const formData = new FormData();
        formData.append('file', _.get(file, '[0].file'));
        if (groupId) {
            formData.append('groupId', groupId);
        }
        const res = await post(url, formData, { headers: { 'content-type': 'multipart/form-data' }, timeout: 600000 });
        return _.get(res, 'resources');
    }

    async exportPermission(groupId: string):Promise<any> {
        const url = `${URL_PATH}/export`;
        const res = await post(url, { groupId });
        return res;
    }
}

export default new PermissionService();
